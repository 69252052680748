export const copyString = {
  user_breadcrumb: "Wallet holder",
  add_wallet_holder_page_title: "Add Wallet Holder",
  add_user_button_label: "Add wallet holder",
  bulk_add_users_button_label: "Bulk add wallet holders",
  export_users_button_label: "Export wallet holders",
  export_transfers: "Export transfers",
  export_successful: "Export data successful",
  export_failure: "Unable to export data.",
  wallet_holder_page_title: "Wallet Holders",
  applicants_title: "Applicants",
  user_status_column_header: "User Status",
  wallet_status_column_header: "Wallet Status",
  card_status_column_header: "Card Status",
  last_transaction_column_header: "Last Transaction",
  date_of_transaction: "Date of transaction",
  reference_no: "Reference no.",
  email_column_header: "Email",
  actions_column_header: "Actions",
  personal_information: "Personal Information",
  title: "Title",
  first_name: "Name",
  surname: "Surname",
  preferred_name: "Preferred name",
  gender: "Gender",
  nationality: "Nationality",
  date_of_birth: "Date of birth",
  identification_type: "Identification type",
  identification_no: "Identification number",
  country_of_issue: "ID Issuing Country",
  contact_info: "Contact Information",
  email: "Email",
  phone: "Contact number",
  address: "Address",
  billing_delivery_address1: "Address 1",
  billing_delivery_address2: "Address 2",
  billing_delivery_city: "City",
  billing_delivery_state: "State",
  billing_delivery_country: "Country",
  billing_delivery_zip_code: "Postal Code",
  account_information: "Account Information",
  created_at: "Created at",
  last_status_change_at: "User status last modified on",
  wallet_status: "Wallet status",
  last_login: "Last login",
  new_password: "New password",
  confirm_password: "Re-enter new password",
  password_rule:
    "At least 8 characters long, include at least a number and a special character. ",
  our_fee: "Our fee",
  they_receives: "They receive",
  your_balance: "Your balance",
  transfer: "Transfer",
  to: "To",
  confirm_transfer: "Confirm Transfer",
  you_send: "You send",
  comments_for_recipient: "Optional comment",
  comments_optional: "Comment (optional)",
  suggestions: "Suggestions",
  field_alphanumeric_error_message: "Please enter letters and numbers only.",
  field_number_error_message: "Please enter numbers only.",
  field_date_error_message: "Please enter a valid date.",
  field_blank_error_message: "Field cannot be left blank.",
  field_password_is_not_strong_errror_message:
    "The password you entered does not meet the minimum security requirements.'",
  field_confirm_password_not_match_error_message: "Passwords do not match.",
  field_email_error_message: "Please enter a valid email address.",
  invite_action: "Invite",
  resend_invite_action: "Resend invite",
  bulk_transfer: "Bulk transfer",
  bulk_transfer_info:
    "Transfer funds to multiple wallet holders at once by uploading a CSV file.",
  fill_out_csv: "Download and fill out CSV.",
  download_template: "Download template",
  upload_csv: "Upload the CSV.",
  upload_file: "Upload file",
  uploading: "Uploading...",
  bulk_transfer_error_title:
    "CSV file has errors, please fix the errors and re-upload:",
  download_error_report: "Download error report",
  download_success_report: "Download Validated report",
  download_success_disbursal_report: "Download Disbursal report",
  csv_import_in_progress: "CSV import in Progress...",
  csv_upload_in_progress: "CSV upload in Progress...",
  csv_validation_in_progress: "CSV validation is in Progress...",
  bulk_payout_in_progress: "Bulk Payout is in Progress...",
  csv_upload_in_progress_info:
    "Please keep your browser open until the process is complete.",
  csv_has_errors: "CSV file has errors, please fix the errors and re-upload:",
  confirm: "Confirm",
  no_of_transfers: "No. of transfers",
  total_disbursable: "Total disbursable",
};
