import colors from "constants/colors";

/**
 * This has been shamelessly stolen from services/core/cards
 * Will move these things into separate modules for BOv2
 */
export enum CardStatus {
  /**
   * The card has been ordered by the user or us but not yet assigned.
   */
  Requested = "REQUESTED",

  /**
   * The card has been assigned to an end user. Same as `pending activation`
   * above.
   */
  Assigned = "ASSIGNED",

  /**
   * The card has been assigned and mailed / by other means sent to the end
   * user.
   */
  Sent = "SENT",

  /**
   * The card is active and can be used.
   */
  Active = "ACTIVE",

  /**
   * The card is temporarily suspended (same as "locked", above).
   */
  Blocked = "BLOCKED",

  /**
   * The card is permanently terminated.
   */
  Archived = "ARCHIVED",
}

export type Card = {
  status: CardStatus;
  masked_card_number: string;
  id: string;
  type: "physical" | "virtual";
  is_active: boolean;
  expiry: string;
  created_at: string;
  sent_at: string;
  issued: string;
};

export interface IApplicantsProps {
  email: string;
  name: string;
  surname: boolean;
  phone_code: string;
  phone_number: string;
  status: string;
  created_at: string;
  id: string;
  email_verification_sent_at: string;
  seafarer_rank: string;
  email_verified_at: string;
  gender: string;
}

export interface IWalletHolderProps {
  kyc_mode: string;
  title: string;
  account_status: boolean;
  billing_delivery_address1: string;
  billing_delivery_address2: string;
  billing_delivery_city: string;
  billing_delivery_country: string;
  billing_delivery_state: string;
  country_of_issue: string;
  billing_delivery_zip_code: string;
  gender: string;
  // blocked: boolean;
  country_code: string;
  created_at: string;
  date_of_birth: string;
  email: string;
  email_verified_at: string;
  first_name: string;
  id: string;
  last_login: string;
  last_name: string;
  nationality: string;
  phone_code: string;
  phone: string;
  preferred_name: string;
  role: string;
  // status: string;
  type: string;
  updated_at: string;
  verification_status: string;
  last_transaction: string;
  wallet_status: string;
  last_verification_at: string;
  last_status_change_at: string;
  card_status?: string;
  card_statuses?: Card[];
  last_card_status_change_at?: string;
  identification_type?: string;
  identification_no?: string;
  risk_status: string;
  kyc_status_last_modified_on?: string;
  card: IWalletHolderCardProps | null;
  activation_status: string;
  wallet_number: string;
  customer_hash_id: string;
}

export interface IWalletHolderCardProps {
  created_at: string;
  id: string;
  masked_card_number: string;
  status: string;
  type: string;
}

export const wallerHolderInit = {
  kyc_mode: "NOT_SUBMITTED",
  title: "",
  account_status: false,
  billing_delivery_address1: "",
  billing_delivery_address2: "",
  billing_delivery_city: "",
  billing_delivery_state: "",
  billing_delivery_country: "",
  country_of_issue: "",
  customer_hash_id: "",
  billing_delivery_zip_code: "",
  gender: "",
  // blocked: false,
  country_code: "",
  created_at: "",
  date_of_birth: "",
  email: "",
  email_verified_at: "",
  first_name: "",
  id: "",
  last_login: "",
  last_name: "",
  nationality: "",
  phone_code: "",
  phone: "",
  preferred_name: "",
  role: "",
  // status: '',
  type: "",
  updated_at: "",
  verification_status: "",
  last_transaction: "",
  wallet_status: "",
  last_verification_at: "",
  last_status_change_at: "",
  identification_type: "",
  identification_no: "",
  risk_status: "CLEARED",
  card: null,
  activation_status: "",
  wallet_number: "",
};

type IStatus = {
  [n: string]: {
    code: string;
    name: string;
    color: string;
    bg: string;
    border?: string;
  };
};

export const RISK_STATUS = {
  SUSPENDED: "suspended",
  CLEARED: "cleared",
  BLOCKED: "blocked",
};

export const COMPLIANCE_STATUS = {
  CLEARED: "cleared",
  REJECTED: "rejected",
  ARCHIVED: "archived",
};

export const KYC_SUB_STATUS = {
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const KYC_STATUS = {
  NOT_SUBMITTED: "not_submitted",
  SUBMITTED: "submitted",
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const VERIFICATION_STATUS = {
  NOT_SUBMITTED: "not_submitted",
  PREPARING: "preparing",
  SUBMITTED: "submitted",
  REJECTED: "rejected",
  APPROVED: "approved",
};

export const WALLET_STATUS: IStatus = {
  ARCHIVED: {
    code: "ARCHIVED",
    name: "Archived",
    color: "#555566",
    bg: "#CCCCDD",
  },
  WH_NotSubmitted: {
    code: "wh_notsubmitted",
    name: "Not Submitted",
    color: colors.Grey700,
    border: "#CCCCDD",
    bg: "transparent",
  },
  WH_NotApproved: {
    code: "wh_notapproved",
    name: "Not Approved",
    color: colors.Grey700,
    bg: "transparent",
  },
  WH_ApprovalSubmitted: {
    code: "wh_approvalsubmitted",
    name: "Approval Submitted",
    color: colors.Grey700,
    bg: "transparent",
  },
  SUSPENDED: {
    code: "suspended",
    name: "Suspended",
    color: "#994400",
    bg: "#FFEEDD",
  },
  Active: {
    code: "active",
    name: "Active",
    color: colors.Success500,
    bg: colors.Success300,
  },
  BLOCKED: {
    code: "blocked",
    name: "Blocked",
    color: colors.Error500,
    bg: colors.Error300,
  },
  WH_Rejected: {
    code: "wh_rejected",
    name: "Rejected",
    color: colors.Error500,
    bg: colors.Error300,
  },
};

export const USER_STATUS: IStatus & {
  [n: string]: { style: "neutral" | "error" | "success" | "disabled" };
} = {
  not_invited: {
    code: "not_invited",
    name: "Not Invited",
    color: "#555566",
    bg: "transparent",
    border: "#CCCCDD",
    style: "neutral",
  },
  not_activated: {
    code: "not_activated",
    name: "Not Activated",
    color: "#555566",
    bg: "transparent",
    border: "#CCCCDD",
    style: "neutral",
  },
  invited: {
    code: "invited",
    name: "Invited",
    color: "#994400",
    bg: "#FFEEDD",
    style: "neutral",
  },
  activated: {
    code: "activated",
    name: "Activated",
    color: colors.Success500,
    bg: colors.Success300,
    style: "success",
  },
  archived: {
    code: "archived",
    name: "Archived",
    color: "#555566",
    bg: "#CCCCDD",
    style: "disabled",
  },
  unknown: {
    code: "unknown",
    name: "Unknown",
    color: "#555566",
    bg: "#CCCCDD",
    style: "disabled",
  },
};

export const CARD_STATUS: IStatus = {
  assigned: {
    code: "assigned",
    name: "Assigned",
    color: "#555566",
    bg: colors.White,
    border: "#CCCCDD",
  },
  sent: {
    code: "sent",
    name: "Sent",
    color: "#9A4A00",
    bg: "#FFF3E0",
  },
  active: {
    code: "active",
    name: "Active",
    color: colors.Success500,
    bg: colors.Success300,
  },
  archived: {
    code: "archived",
    name: "Archived",
    color: "#555566",
    bg: "#CCCCDD",
  },
  blocked: {
    code: "blocked",
    name: "Blocked",
    color: colors.Error500,
    bg: colors.Error300,
  },
};

export const TITLE = ["Mr", "Miss", "Dr", "Mrs", "Madam"];

export const GENDER = ["male", "female"];
