import {
  Box,
  Card,
  CardContent,
  TablePagination,
  TableSortLabel,
  Typography,
  TextField,
  InputAdornment,
  Skeleton,
  SxProps,
  Tooltip,
  Button,
  TableCellProps,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IColumnProps } from "interfaces/mui-column";
import { IApplicantsProps } from "interfaces/wallet-holder";
import { ChangeEvent, useEffect, useState, ReactElement, useRef } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toast/action";
import {
  IFilterProps,
  getApplicants,
} from "utils/apiProvider";
import { t } from "utils/translate";
import {
  Search,
  Clear,
} from "@mui/icons-material";
import colors from "constants/colors";
import { useQuery } from "utils/useQuery";
import { omit } from "utils/omit";
import { isDeeplyEqual } from "utils/isDeeplyEqual";
import { UTCLocalTimeStampCompact } from "components/shared/UTCLocalTimestampComponent";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

dayjs.extend(relativeTime);

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const isFilterProps = (obj: unknown): obj is IFilterProps =>
  typeof obj === "object" &&
  !!obj &&
  "filter" in obj &&
  "cursor" in obj &&
  "limit" in obj &&
  "mode" in obj &&
  "sort" in obj &&
  typeof obj.filter === "object" &&
  !!obj.filter &&
  Object.entries(obj.filter).every(
    ([k, v]) => typeof k === "string" && typeof v === "string"
  ) &&
  (typeof obj.cursor === "string" || obj.cursor === null) &&
  typeof obj.limit === "number" &&
  typeof obj.mode === "string" &&
  (obj.mode === "or" || obj.mode === "and") &&
  Array.isArray(obj.sort) &&
  obj.sort.every((x) => Object.entries(x).length === 1);

const defaultFilter: IFilterProps = {
  cursor: null,
  limit: 10,
  sort: [
    {
      created_at: "desc",
    },
  ],
  filter: {}
};

const getDefaultFilter = (): IFilterProps => {
  let decodedFilterFromQuery: null | IFilterProps = null;
  try {
    const queryFilters = JSON.parse(
      atob(new URL(window.document.URL).searchParams.get("filter") ?? "")
    );
    if (isFilterProps(queryFilters)) decodedFilterFromQuery = queryFilters;
  } catch (e) {
    console.log("Could not load filter from query", e);
  }
  return decodedFilterFromQuery ?? defaultFilter;
};

const ApplicantsList = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryRow = query.get("row");
  const [openRow] = useState(queryRow || "");
  const [rows, setRows] = useState<IApplicantsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextCursor, setNextCursor] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<IFilterProps>(getDefaultFilter());
  const enableClearButton = !isDeeplyEqual(
    omit(filter, "limit"),
    omit(omit(defaultFilter, "limit"))
  );
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState<string>(
    filter.filter?.["name"] ?? ""
  );
  const [hasUsedSearchInput, setHasUsedSearchInput] = useState(false);
  const debouncedSearchInput = useDebounce<string>(searchInput, 500);

  const ref = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      ref.current !== undefined &&
      typeof ref.current.clientHeight === "number" &&
      filter.limit === 0
    ) {
      const numRowsToFetch = Math.max(
        5,
        Math.min(25, Math.floor((ref.current?.clientHeight ?? 0) / 35) - 1)
      );
      setFilter((filter) => ({ ...filter, limit: numRowsToFetch }));
    }
  }, [ref.current?.clientHeight, filter.limit]);

  useEffect(() => {
    const url = new URL(window.document.URL);
    if (openRow) url.searchParams.set("row", String(openRow));
    else url.searchParams.delete("row");
    history.replaceState(null, "", url.toString());
  }, [openRow]);

  useEffect(() => {
    const url = new URL(window.document.URL);
    if (filter.limit) {
      url.searchParams.set("filter", btoa(JSON.stringify(filter)));
      history.replaceState(null, "", url.toString());
    }
  }, [JSON.stringify(filter)]);

  type UserColumn = {
    field: string;
    headerName: string;
    sortable: boolean;
    formatter?: (p: IApplicantsProps) => ReactElement | string;
    width?: number;
    cellSx?: (p: IApplicantsProps) => SxProps;
    tableCellProps?: (p: IApplicantsProps) => TableCellProps;
  };

  const userColumns: UserColumn[] = [
    {
      width: 190,
      field: "name",
      headerName: t("first_name"),
      sortable: true,
      formatter: (p: IApplicantsProps) => {
        const name = [p.name, p.surname].filter((x) => x).join(" ");
        return (
          <>{name}</>
        );
      },
    },
    {
      width: 250,
      field: "email",
      headerName: t("email"),
      sortable: false,
      formatter(p) {
        return (
          <Tooltip placement={"right"} title={p.email}>
            <span>{p.email}</span>
          </Tooltip>
        );
      }
    },
    {
      width: 170,
      field: "phone",
      headerName: "Phone",
      sortable: false,
      formatter(p) {
        return <>{formatPhoneNumber(p.phone_code, p.phone_number, true)}</>;
      }
    },
    {
      width: 170,
      field: "rank",
      headerName: "Rank",
      sortable: false,
      formatter(p) {
        return <>{p.seafarer_rank}</>;
      }
    },
    {
      width: 200,
      field: "created_at",
      headerName: "Registered at",
      sortable: true,
      formatter: (p: IApplicantsProps) => {
        return <UTCLocalTimeStampCompact date={p.created_at} />;
      },
    },
  ];

  function Row(props: { row: any }) {
    return (
      <>
        <TableRow hover sx={{ textDecoration: "none" }} tabIndex={-1}>
          {userColumns.map((column) => {
            const value = props.row[column.field as keyof IApplicantsProps];
            const sxProps: SxProps = {
              ...(column.cellSx?.(props.row) ?? {}),
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              position: "relative",
            };
            const tableCellProps = column.tableCellProps?.(props.row) ?? {};
            return (
              <TableCell sx={sxProps} {...tableCellProps} key={column.field}>
                {column.formatter ? column.formatter(props.row) : value}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const resp = await getApplicants({ ...filter, cursor: nextCursor });
      setRows(resp.data);
      setLoading(false);
      setTotal(resp.total_count);
      setNextCursor(resp.next_cursor);
    } catch (err) {
      if (err instanceof AxiosError)
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title: "Can not load applicants",
            })
          );
        }
    }
  };

  const handleRowsPerPageChange = (e: ChangeEvent<any>) => {
    setNextCursor(null);
    setFilter({
      ...filter,
      cursor: null,
      limit: e.target.value,
    });
  };

  useEffect(() => {
    if (filter.limit !== 0) fetchUsers();
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setFilter((filter) => {
      setNextCursor(null);
      return {
        ...filter,
        cursor: null,
        filter: debouncedSearchInput
          ? {
            name: debouncedSearchInput,
            surname: debouncedSearchInput,
            email: debouncedSearchInput,
          }
          : {
            ...Object.fromEntries(
              Object.entries(filter.filter ?? {}).filter(
                ([k]) =>
                  ![
                    "name",
                    "surname",
                    "email",
                  ].includes(k)
              )
            ),
          },
      };
    });
  }, [debouncedSearchInput, hasUsedSearchInput]);

  const handleSort = (column: IColumnProps) => {
    const findExisted = filter.sort?.find(
      (s: any) => Object.keys(s)?.[0] === column.field
    );
    const order =
      findExisted?.[Object.keys(findExisted)?.[0]] === "desc" ? "asc" : "desc";
    setFilter({
      ...filter,
      sort: [
        {
          [column.field]: order,
        },
      ],
    });
  };

  const onChangeSearchInput = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchInput(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
          flexDirection: ["row-reverse", "row-reverse", "row", "row"],
        }}
      >
        <Typography sx={{ display: { xs: "none", md: "block" } }} variant="h1">
          {t("applicants_title")}
        </Typography>
      </Box>
      <Card>
        <CardContent sx={{ position: "relative", minHeight: 250 }}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{ width: 420, marginBottom: 15 }}
              placeholder={"Search by name, email"}
              value={searchInput}
              onChange={onChangeSearchInput}
              onKeyDown={() => setHasUsedSearchInput(true)}
            ></TextField>
            <Button
              onClick={() => {
                setHasUsedSearchInput(false);
                setSearchInput("");
                setPage(1);
                setNextCursor(null);
                setFilter(defaultFilter);
              }}
              disabled={!enableClearButton}
              sx={{
                marginLeft: 2,
                height: "40px",
                position: "relative",
                top: "-1px",
              }}
              variant="outlined"
              size="large"
              startIcon={<Clear />}
            >
              Clear
            </Button>
          </Box>
          {/* @ts-expect-error Ignore ref issue */}
          <TableContainer
            ref={ref}
            sx={{
              scrollbarGutter: "stable",
              flexGrow: 1,
              height: "calc(100vh - 350px)",
              marginTop: 0,
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              sx={{
                tableLayout: "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {userColumns.map((column: IColumnProps) => (
                    <TableCell
                      sx={{ backgroundColor: colors.Grey100 + " !important" }}
                      key={column.field}
                      width={column.width ? `${column.width}px` : "auto"}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={
                            !!filter.sort?.find(
                              (s: any) => Object.keys(s)?.[0] === column.field
                            )
                          }
                          onClick={() => handleSort(column)}
                          direction={
                            filter.sort?.find(
                              (s: any) => Object.keys(s)?.[0] === column.field
                            )?.[column.field]
                              ? filter.sort?.find(
                                (s: any) =>
                                  Object.keys(s)?.[0] === column.field
                              )?.[column.field]
                              : "desc"
                          }
                        >
                          {column.headerName}
                        </TableSortLabel>
                      ) : (
                        <>{column.headerName}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from({ length: filter.limit }, (_, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      {Array.from({ length: userColumns.length }, (_, i) => (
                        <TableCell
                          key={i}
                          width={
                            userColumns[i].width
                              ? `${userColumns[i].width}px`
                              : "auto"
                          }
                        >
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                  : rows.map((row: any) => {
                    return <Row key={row.id} row={row} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={total}
            rowsPerPage={filter.limit}
            page={page - 1}
            onPageChange={(event, newPage) => {
              setPage(newPage + 1);
              setFilter({
                ...filter,
                cursor: nextCursor,
              });
            }}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ApplicantsList;
