import { InfoOutlined } from "@mui/icons-material";
import {
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import colors from "constants/colors";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const statusChipStyle = {
  error: { color: colors.Error500, bg: colors.Error300 },
  disabled: { color: "#555566", bg: "#CCCCDD" },
  neutral: { color: colors.Grey700, border: "#CCCCDD", bg: "transparent" },
  success: { color: colors.Success500, bg: colors.Success300 },
};

const timeZoneAbbreviation = () => {
  try {
    const d = new Date();
    const dateAsString: string = d.toString();
    const timezone = dateAsString.match(/\(([^)]+)\)$/)?.[1];
    const matches = timezone?.match(/\b(\w)/g);
    const abbreviations = matches?.join("");
    return abbreviations;
  } catch (e) {
    console.log("ERROR", e);
  }
  return "";
};

// const timeZoneDifference = () => {
//   const d = new Date();
//   const difference = d.getTimezoneOffset() / 60;
//   return difference;
// };

export const UTCLocalTimeStamp = ({ date }: { date?: string }) => {
  if (!date) return "-";

  const utcTime = dayjs.utc(date);
  const localTime = utcTime.local();

  return `${utcTime.format("YYYY-MM-DD HH:mm")} UTC (${localTime.format(
    "YYYY-MM-DD HH:mm"
  )} ${timeZoneAbbreviation()})`;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    sx: { pr: "8px", pl: "8px", pt: "4px", pb: "4px" },
  },
}));

export const UTCLocalTimeStampCompact = ({ date }: { date?: string }) => {
  if (!date) return "-";

  const utcTime = dayjs.utc(date);
  const localTime = utcTime.local();

  const toolTip = `${localTime.format(
    "YYYY-MM-DD HH:mm"
  )} ${timeZoneAbbreviation()}`;

  return (
    <LightTooltip title={toolTip}>
      <Stack alignItems="center" direction="row">
        <Typography sx={{ minWidth: 160 }} fontSize={14}>
          {`${utcTime.format("YYYY-MM-DD HH:mm")} UTC`}
        </Typography>
        <InfoOutlined
          sx={{ color: colors.Neutral400, fontSize: 16, ml: "4px" }}
        />
      </Stack>
    </LightTooltip>
  );
};
